import React from 'react';
import dayjs from 'dayjs';
import Countdown from 'react-countdown';
import { Toast } from 'react-hot-toast';
import { NotificattionContainer } from '../NotificattionContainer';
import { getImageByURL, sendAnalyticsHandler } from '@utils';
import { Trans } from 'react-i18next';
import { isTapX } from '@config/global';
import { Button } from '@components/common/elements';
import { AnalyticsEventType, ButtonType } from '@types';
import { useLocales } from '@lib/hooks';
import { commonUiConfig } from '@config/common-ui';
import { pathsConfig } from '@config/paths';
import { NavigateFunction } from 'react-router-dom';
import { userSelectors } from '@store/reducers/user/selectors';
import { useSelector } from 'react-redux';
import s from './style.module.scss';

type DataType = {
	minutes: number;
	seconds: number;
};

type PropsType = {
	t: Toast;
	onClose?: () => void;
	navigate: NavigateFunction;
	langUrlPrefix: string;
};

export const AuthedDepositeBonusToast = (props: PropsType) => {
	const { t, langUrlPrefix, onClose, navigate } = props;

	const { createdAt } = useSelector(userSelectors.userInfo);

	const { localizeText } = useLocales({
		path: 'components/common/sections',
		node: 'ExtraOffers'
	});

	const reffilHandler = () => {
		navigate(langUrlPrefix + pathsConfig?.payment?.link);

		sendAnalyticsHandler(AnalyticsEventType?.popup_click_to_action, {
			name_popup: 'Deposite bonus authed user'
		});
	};

	const renderer = ({ minutes, seconds }: DataType) => {
		return (
			<Trans
				ns='components/common/sections/translation'
				i18nKey={'ExtraOffers.left_time'}
				values={{
					time: `${minutes < 10 ? `0${minutes}` : minutes}:${
						seconds < 10 ? `0${seconds}` : seconds
					}`
				}}
				components={{ red: <span className={s.redText} /> }}
			/>
		);
	};

	return (
		<NotificattionContainer
			onClose={onClose}
			duration={commonUiConfig.infinityDuration}
			t={t}
			absoluteCross
		>
			<div className={s.content}>
				<div className={s.topAuthBlock}>
					<img
						alt='5X'
						className={s.img_small}
						src={getImageByURL(
							`../../../../../assets/img/common/5xBanner${isTapX ? '-tapx' : ''}.webp`
						)}
					/>

					<div className={s.authDescr}>
						<div className={s.descrText}>{localizeText('reffil_double')}</div>
						<div className={s.counter}>
							<Countdown
								date={dayjs(createdAt).add(1, 'h').format()}
								renderer={renderer}
							/>
						</div>
					</div>
				</div>
				<Button
					className={s.registerBtn}
					type={ButtonType?.primary}
					withoutGlare
					handle={reffilHandler}
					text={localizeText('get')}
				/>
			</div>
		</NotificattionContainer>
	);
};
