import {
	DeleteBonusBalancesPA,
	IBonusInitialState,
	SetAllLevelsPA,
	SetAllRMWBonusPA,
	SetBonusBalancesHistoryPA,
	SetBonusBalancesPA,
	SetBonusGameOpenPA,
	SetCashbackInfoPA,
	SetFreeSpinsInfoPA,
	SetPremiumSubscriptionConditionsPA,
	SetPromocodeInputValuePA,
	SetPromocodeStatusPA,
	SetPromocodeUsingBanPA,
	SetReferralLinkPA,
	SetReferralRewardsPA,
	SetReferralsQtyAndPendingRewardsPA,
	SetSocialBonusTask,
	SetTelegramCodePA
} from '@types';

export const setAllLevels = (state: IBonusInitialState, action: SetAllLevelsPA) => {
	state.allLevels = action.payload;
};

export const setBonusGameOpen = (
	state: IBonusInitialState,
	action: SetBonusGameOpenPA
) => {
	state.bonusGameOpen = action.payload;
};

export const setAllRMWBonus = (state: IBonusInitialState, action: SetAllRMWBonusPA) => {
	state.allRMWBonus = action.payload;
};

export const setTelegramCode = (state: IBonusInitialState, action: SetTelegramCodePA) => {
	state.tasks.tg = action.payload;
};

export const setSocialBonusTask = (
	state: IBonusInitialState,
	action: SetSocialBonusTask
) => {
	state.socialBonusTasks = action.payload;
};

export const setPromocodeStatus = (
	state: IBonusInitialState,
	action: SetPromocodeStatusPA
) => {
	state.promocode.status = action.payload;
};

export const setPromocodeInputValue = (
	state: IBonusInitialState,
	action: SetPromocodeInputValuePA
) => {
	state.promocode.inputValue = action.payload;
};

export const setPromocodeUsingBan = (
	state: IBonusInitialState,
	action: SetPromocodeUsingBanPA
) => {
	state.promocode.isForbiddenForUsing = action.payload.isForbiddenForUsing;
	state.promocode.usingForbiddenTime = action.payload.usingForbiddenTime;
};

export const setReferralLink = (state: IBonusInitialState, action: SetReferralLinkPA) => {
	state.referral.link = action.payload;
};

export const setReferralRewards = (
	state: IBonusInitialState,
	action: SetReferralRewardsPA
) => {
	state.referral.rewards = action.payload;
};

export const setReferralsQtyAndPendingRewards = (
	state: IBonusInitialState,
	action: SetReferralsQtyAndPendingRewardsPA
) => {
	state.referral.totalUserQty = action.payload.totalUserQty;
	state.referral.pendingRewardsAmount = action.payload.pendingRewardsAmount;
	state.referral.totalRewardsSum = action.payload.totalRewardsSum;
};

export const setPremiumSubscriptionConditions = (
	state: IBonusInitialState,
	action: SetPremiumSubscriptionConditionsPA
) => {
	state.premiumSubscriptionConditions = action.payload;
};

export const setFreeSpinsInfo = (
	state: IBonusInitialState,
	action: SetFreeSpinsInfoPA
) => {
	state.freeSpinsInfo = action.payload;
};

export const setBonusBalances = (
	state: IBonusInitialState,
	action: SetBonusBalancesPA
) => {
	state.bonusBalances = action.payload;
};

export const setBonusBalancesHistory = (
	state: IBonusInitialState,
	action: SetBonusBalancesHistoryPA
) => {
	state.bonusBalancesHistory = action.payload;
};

export const deleteBonusBalances = (
	state: IBonusInitialState,
	action: DeleteBonusBalancesPA
) => {
	state.bonusBalances = state.bonusBalances?.filter((e) => e?.id !== action.payload);
};

export const setCashbackInfo = (state: IBonusInitialState, action: SetCashbackInfoPA) => {
	state.currentWeekCashbackAmount = action.payload;
};

export const usedPastWeekCashback = (state: IBonusInitialState) => {
	// @ts-ignore
	state.cashback.pastWeekAmount = 0;
};

export const clearBonus = (state: IBonusInitialState) => {
	state.allLevels = [];
	state.allRMWBonus = [];
	state.tasks = {
		tg: {
			accessCode: '',
			botUrl: ''
		}
	};
	state.socialBonusTasks = [];
	state.promocode.status = null;
	state.promocode.isForbiddenForUsing = false;
	state.promocode.usingForbiddenTime = 0;
	state.promocode.inputValue = '';
	state.premiumSubscriptionConditions = null;
	state.freeSpinsInfo = null;
};
