import { setBonusBalancesHistory } from '@store/reducers/bonus/dispatchers';
import { BonusService } from './BonusService';

export const getBonusHistory = async () => {
	const { status, data } = await BonusService.getBonusHistory();

	if (status === 200) {
		setBonusBalancesHistory(data);
	}
};
