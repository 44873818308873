import { GameProviderService } from '@lib/api/game-provider/GameService';
import { IProviderGame, SessionStorageParams } from '@types';
import { filterStringGenerator, FilterType } from './filterStringGenerator';

const getWaggeringGames = async ({
	setWageringGames,
	isSlice = false,
	slice = 3,
	limit = 50
}: {
	setWageringGames: React.Dispatch<React.SetStateAction<IProviderGame[] | undefined>>;
	isSlice?: boolean;
	slice?: number;
	limit?: number;
}) => {
	const haveInSessionStorage = window?.sessionStorage?.getItem(
		SessionStorageParams?.wageringGamesList
	);

	let games = haveInSessionStorage ? JSON.parse(haveInSessionStorage) : null;

	if (!games || games?.length === 0) {
		const result = GameProviderService.requestGames({
			limit: limit,
			page: 1,
			filter: filterStringGenerator(FilterType.EQ, { isFreezeBalancePlay: true })
		});

		const res = await result;

		const currentGames = res?.data?.data;

		window?.sessionStorage?.setItem(
			SessionStorageParams?.wageringGamesList,
			JSON.stringify(currentGames)
		);

		games = currentGames;
	}

	const sliced: IProviderGame[] = isSlice ? games?.slice(0, slice) : games;

	setWageringGames(sliced);
};

export default getWaggeringGames;
