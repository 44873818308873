import React, { useMemo } from 'react';
import { Ripple } from '..';
import { ButtonP, ButtonType } from '@types';
import s from './style.module.scss';
import cn from 'classnames';

export const Button = (props: ButtonP) => {
	const {
		isDisabled,
		btnType = 'button',
		className = '',
		styles,
		text,
		handle,
		type = ButtonType.default,
		rippleAnimation,
		rippleColor,
		rippleDuration,
		rippleOpacity,
		children,
		withoutGlare
	} = props;

	const Glare = () =>
		useMemo(() => {
			return <div className={s.glare} />;
		}, []);

	const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (handle) {
			handle(event);
		}
	};

	return (
		<button
			type={btnType}
			style={styles}
			disabled={isDisabled}
			className={cn(s.button, s[`button${type}`], className)}
			onClick={clickHandler}
		>
			{type === ButtonType.primary && !withoutGlare && <Glare />}
			{text ?? children}
			{rippleAnimation && (
				<Ripple
					duration={rippleDuration}
					color={rippleColor}
					opacity={rippleOpacity}
				/>
			)}
		</button>
	);
};
