import { instance } from '@lib/api';
import {
	AxiosResponsePromise,
	Currencies,
	IBonusInitialState,
	IReferralReward
} from '@types';

// rmw
type AllRMWBonus = (
	currency: Currencies
) => AxiosResponsePromise<IBonusInitialState['allRMWBonus']>;
type CancelRMWBonus = () => AxiosResponsePromise<string>;
// task
type GetTelegramCode = () => AxiosResponsePromise<{ code: string; link: string }>;
type GetSocialBonusTasks = () => AxiosResponsePromise<
	IBonusInitialState['socialBonusTasks']
>;
type CompleteSocialBonusTask = (taskId: number) => AxiosResponsePromise<any>;
// promo
type InitPromocode = (params: { code: string }) => AxiosResponsePromise<any>;
// referral
type ReferralRewards = () => AxiosResponsePromise<Array<IReferralReward>>;
type ReferralsQtyAndPendingRewards = () => AxiosResponsePromise<
	Pick<
		IBonusInitialState['referral'],
		'totalUserQty' | 'pendingRewardsAmount' | 'totalRewardsSum'
	>
>;
type UseReferralPendingRewards = () => AxiosResponsePromise<any>;
type UpdateReferralCode = () => AxiosResponsePromise<string>;
// premium sub
type GetPremiumSubscriptionConditions = () => AxiosResponsePromise<
	IBonusInitialState['premiumSubscriptionConditions']
>;
type SubscribePremium = () => AxiosResponsePromise<any>;
// free spins
type GetFreeSpinsQty = () => AxiosResponsePromise<
	Array<{
		amount: number;
		gameId: number;
		gameKey: string;
		id: number;
		winAmount: number;
		isVisited: boolean;
	}>
>;
// cashback
type GetCurrentWeekCashbackAmount = () => AxiosResponsePromise<number>;
type GetPastWeekCashbackAmount = () => AxiosResponsePromise<number>;
type ApplyCashback = () => AxiosResponsePromise<string>;

type getBonusBalances = () => AxiosResponsePromise<IBonusInitialState['bonusBalances']>;
type getBonusHistory = () => AxiosResponsePromise<
	IBonusInitialState['bonusBalancesHistory']
>;

type setBonusBalancesVisited = (id: number) => AxiosResponsePromise<any>;
type setBonusBalancesCancel = () => AxiosResponsePromise<any>;

type setFreespinsVisited = (id: number) => AxiosResponsePromise<any>;

interface IBetService {
	// rmw
	allRMWBonus: AllRMWBonus;
	cancelRMWBonus: CancelRMWBonus;
	// task
	getTelegramCode: GetTelegramCode;
	getSocialBonusTasks: GetSocialBonusTasks;
	completeSocialBonusTask: CompleteSocialBonusTask;
	// promo
	initPromocode: InitPromocode;
	// referral
	referralRewards: ReferralRewards;
	referralsQtyAndPendingRewards: ReferralsQtyAndPendingRewards;
	referralPendingRewards: UseReferralPendingRewards;
	updateReferralCode: UpdateReferralCode;
	// premium sub
	getPremiumSubscriptionConditions: GetPremiumSubscriptionConditions;
	subscribePremium: SubscribePremium;
	// free spins
	getFreeSpinsQty: GetFreeSpinsQty;
	// cashback,
	getCurrentWeekCashbackAmount: GetCurrentWeekCashbackAmount;
	getPastWeekCashbackAmount: GetPastWeekCashbackAmount;
	applyCashback: ApplyCashback;

	getBonusBalances: getBonusBalances;
	getBonusHistory: getBonusHistory;
	setBonusBalancesVisited: setBonusBalancesVisited;
	setBonusBalancesCancel: setBonusBalancesCancel;
	setFreespinsVisited: setFreespinsVisited;
}

enum BonusEndpoints {
	// rmw
	allRMWBonus = '/rmw-bonus',
	cancelRMWBonus = '/rmw-bonus/user',
	// task
	getTelegramCode = '/auth/social-networks/telegram',
	getSocialBonusTasks = '/tasks',
	completeSocialBonusTask = '/tasks',
	// promo
	initPromocode = '/promo',
	// referral
	referralRewards = '/referral/rewards',
	referralsQtyAndPendingRewards = '/referral/all',
	useReferralPendingRewards = '/referral/bonus/apply',
	updateReferralCode = '/auth/referral-code/update',
	// premium sub
	getPremiumSubscriptionConditions = '/subscription/subscription-cost',
	subscribePremium = '/subscription/subscribe',
	// free spins
	freeSpinsQty = '/freespin/active',
	setFreespinsVisited = '/freespin/setVisited/',
	// cashback
	getCurrentWeekCashbackAmount = '/cashback/current',
	getPastWeekCashbackAmount = '/cashback/last-week',
	applyCashback = '/cashback/apply',
	//bonus-balance
	getBonusBalances = '/bonus-balance',
	getBonusHistory = '/bonus-balance/history',
	setBonusBalancesVisited = '/bonus-balance/setVisited/',
	setBonusBalancesCancel = '/bonus-balance/cancel'
}

export const BonusService: IBetService = {
	// rmw
	allRMWBonus: async (currency: Currencies) =>
		await instance.get(BonusEndpoints.allRMWBonus, { params: { currency } }),
	cancelRMWBonus: async () => await instance.delete(BonusEndpoints.cancelRMWBonus),
	// task
	getTelegramCode: async () => await instance.post(BonusEndpoints.getTelegramCode),
	getSocialBonusTasks: async () => await instance.get(BonusEndpoints.getSocialBonusTasks),
	completeSocialBonusTask: async (taskId) =>
		await instance.post(`${BonusEndpoints.completeSocialBonusTask}/${taskId}/confirm`),
	// promo
	initPromocode: async (params) =>
		await instance.post(BonusEndpoints.initPromocode, params),
	// referral
	referralRewards: async () => await instance.get(BonusEndpoints.referralRewards),
	referralsQtyAndPendingRewards: async () =>
		await instance.get(BonusEndpoints.referralsQtyAndPendingRewards),
	referralPendingRewards: async () =>
		await instance.post(BonusEndpoints.useReferralPendingRewards),
	updateReferralCode: async () => await instance.patch(BonusEndpoints.updateReferralCode),
	// premium sub
	getPremiumSubscriptionConditions: async () =>
		await instance.get(BonusEndpoints.getPremiumSubscriptionConditions),
	subscribePremium: async () => await instance.post(BonusEndpoints.subscribePremium),
	// free spins
	getFreeSpinsQty: async () => await instance.get(BonusEndpoints.freeSpinsQty),
	setFreespinsVisited: async (id: number) =>
		await instance.post(BonusEndpoints.setFreespinsVisited + id),
	// cashback
	getCurrentWeekCashbackAmount: async () =>
		await instance.get(BonusEndpoints.getCurrentWeekCashbackAmount),
	getPastWeekCashbackAmount: async () =>
		await instance.get(BonusEndpoints.getPastWeekCashbackAmount),
	applyCashback: async () => await instance.post(BonusEndpoints.applyCashback),

	//bonus-balance
	getBonusBalances: async () => await instance.get(BonusEndpoints.getBonusBalances),
	getBonusHistory: async () => await instance.get(BonusEndpoints.getBonusHistory),

	setBonusBalancesVisited: async (id: number) =>
		await instance.post(BonusEndpoints.setBonusBalancesVisited + id),

	setBonusBalancesCancel: async () =>
		await instance.post(BonusEndpoints.setBonusBalancesCancel)
};
