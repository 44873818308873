import React from 'react';
import toast, { Toast } from 'react-hot-toast';
import { NotificattionContainer } from '../NotificattionContainer';
import { getImageByURL, sendAnalyticsHandler } from '@utils';
import { Trans } from 'react-i18next';
import { isTapX } from '@config/global';
import { Button } from '@components/common/elements';
import { AnalyticsEventType, ButtonType, ModalType } from '@types';
import { useLocales } from '@lib/hooks';
import { setModalType } from '@store/reducers/common-ui/dispatchers';

import s from './style.module.scss';
import { commonUiConfig } from '@config/common-ui';

type PropsType = {
	t: Toast;
	onClose?: () => void;
};

export const NotAuthedDepositeBonusToast = (props: PropsType) => {
	const { t, onClose } = props;

	const { localizeText } = useLocales({
		path: 'components/common/sections',
		node: 'ExtraOffers'
	});

	const rgisterHandler = () => {
		setModalType(ModalType.auth);

		t?.id && toast.dismiss(t?.id);

		sendAnalyticsHandler(AnalyticsEventType?.popup_click_to_action, {
			name_popup: 'Deposite bonus non-authed user'
		});
	};

	return (
		<NotificattionContainer
			duration={commonUiConfig.infinityDuration}
			t={t}
			onClose={onClose}
			absoluteCross
		>
			<div className={s.content}>
				<img
					alt='5X'
					className={s.img}
					src={getImageByURL(
						`../../../../../assets/img/common/5xBanner${isTapX ? '-tapx' : ''}.webp`
					)}
				/>

				<div className={s.nonAuthBlock}>
					<span className={s.title}>
						<Trans
							ns='components/common/sections/translation'
							i18nKey={'ExtraOffers.doubledeposite'}
							components={{ red: <span className={s.redText} /> }}
						/>
					</span>
					<span className={s.descr}>{localizeText('doubledeposite_descr')}</span>
				</div>

				<Button
					className={s.registerBtn}
					type={ButtonType?.primary}
					withoutGlare
					handle={rgisterHandler}
					text={localizeText('register')}
				/>
			</div>
		</NotificattionContainer>
	);
};
