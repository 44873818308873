import React from 'react';
import toast, { Toast } from 'react-hot-toast';
import { NotificattionContainer } from '../NotificattionContainer';
import { useLocales } from '@lib/hooks';
import { AnalyticsEventType } from '@types';
import { sendAnalyticsHandler, getImageByURL } from '@utils';
import s from './style.module.scss';
import cn from 'classnames';
import { commonUiConfig } from '@config/common-ui';

type PropsType = {
	successInvoiceCount: number;
	balance: number;
	t: Toast;
	confirmNavigation: () => void;
	cancelNavigation: () => void;
};

export const ClosePaymentToast = (props: PropsType) => {
	const { t, successInvoiceCount, confirmNavigation, cancelNavigation, balance } = props;

	const { localizeText } = useLocales({
		path: 'global',
		node: 'notifications'
	});

	const cancelHandler = () => {
		sendAnalyticsHandler(AnalyticsEventType?.payment_modal_stay_dep, {
			payment_modal_goout_dep: `successInvoiceCount: ${successInvoiceCount}, balance: ${balance}`
		});

		toast?.dismiss(t?.id);

		cancelNavigation && cancelNavigation();
	};

	const confirmHandler = () => {
		sendAnalyticsHandler(AnalyticsEventType?.payment_modal_goout_dep, {
			payment_modal_goout_dep: `successInvoiceCount: ${successInvoiceCount}, balance: ${balance}`
		});

		toast?.dismiss(t?.id);

		confirmNavigation && confirmNavigation();
	};

	const image =
		successInvoiceCount < 5
			? getImageByURL('../../../../../../assets/img/common/low_balance.webp')
			: getImageByURL('../../../../../../assets/img/common/payment_leave.webp');

	return (
		<NotificattionContainer
			duration={commonUiConfig.infinityDuration}
			onClose={() => cancelNavigation && cancelNavigation()}
			t={t}
		>
			<div className={s.container}>
				<img
					className={cn(s.img, { [s.moreThen5]: successInvoiceCount > 4 })}
					src={image}
				/>

				<div className={s.textBlock}>
					<h3 className={s.textTitle}>{localizeText('payment_leave')}</h3>
					{successInvoiceCount < 5 && (
						<span className={s.textDescr}>
							{localizeText('payment_leave_descr_bonuces')}
						</span>
					)}

					<div className={s.btns}>
						<button
							className={s.registerBtn}
							onClick={cancelHandler}
						>
							{localizeText('stay')}
						</button>

						<button
							className={s.confirmBtn}
							onClick={confirmHandler}
						>
							{localizeText('leave')}
						</button>
					</div>
				</div>
			</div>
		</NotificattionContainer>
	);
};
