import { bindActions } from '@utils';
import { actions } from './reducer';

export const {
	initUser,
	setBalance,
	setEditingSettings,
	setUpdMeta,
	clearUserData,
	setActiveCurrency,
	setLangCode,
	setNewCurrentUserImage,
	setSocialsLinked,
	setReferralCode,
	setPremiumStatus,
	setUsersLevels,
	setUserLevelId,
	setCreatedAt,
	setBonusBalance,
	setBonusBalanceWager,
	setUserLevelSum,
	setLocationData,
	setDepositWager,
	setBotTelegramId
} = bindActions(actions);
