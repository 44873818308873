import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={20}
		height={20}
		viewBox='0 0 20 20'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<rect
			width='20'
			height='20'
			rx='10'
			fill='url(#paint0_linear_4311_25157)'
		/>
		<path
			d='M13.1113 10.959V12.084H6.95898L6.91211 11.2344L10.5918 5.46875H11.7227L10.498 7.56641L8.38281 10.959H13.1113ZM12.0449 5.46875V14H10.6328V5.46875H12.0449Z'
			fill='#172032'
		/>
		<defs>
			<linearGradient
				id='paint0_linear_4311_25157'
				x1='10'
				y1='0'
				x2='10'
				y2='20'
				gradientUnits='userSpaceOnUse'
			>
				<stop stopColor='white' />
				<stop
					offset='1'
					stopColor='#CBCBCB'
				/>
			</linearGradient>
		</defs>
	</svg>
);

export default SvgComponent;
