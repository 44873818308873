import { PayloadAction as PA } from '@reduxjs/toolkit';

import { CategoriesKeys, Languages, PlatfromQueryE } from '@types';

// common

export enum Socials {
	vk = 'vk',
	telegram = 'telegram'
}

// Auth
export interface IAuthInitialState {
	authType: AuthType | null;
	authToken: string | null;
	isAuthed: boolean;
	receivedCredentials: {
		email: string;
		password: string;
	} | null;
	isResetPasswordMode: boolean;
	isAuthPending: boolean;
	nextAttemptResetPassword: null | string;
	isSendedConfirm: boolean;
}

export enum AuthType {
	signUp = 'signUp',
	signIn = 'signIn'
}

export type SetAuthTypePA = PA<IAuthInitialState['authType']>;
export type AuthPA = PA<boolean>;
export type SetReceivedCredentialsPA = PA<IAuthInitialState['receivedCredentials']>;
export type SetIsSendedConfirmPA = PA<IAuthInitialState['isSendedConfirm']>;
export type SetAuthTokenPA = PA<IAuthInitialState['authToken']>;
export type SetNextAttemptResetPasswordPA = PA<
	IAuthInitialState['nextAttemptResetPassword']
>;

export type SetIsResetPasswordModePA = PA<IAuthInitialState['isResetPasswordMode']>;
export type SetIsAuthPendingPA = PA<IAuthInitialState['isAuthPending']>;

// Round
export interface IUser {
	name: string;
	imgPath: string;
}

export interface IPlayer {
	user: IUser;
	id: number;
	win: string;
	coefficient: number;
	cash: string;
	currency: Currencies;
	lose: boolean;
}

export interface ICurrentRoundState {
	hash: string;
	totalCash: number;
	players: Array<IPlayer>;
	roundProcessing: boolean;
	roundPrepareProcessing: boolean;
	roundPrepareTime: number;
	roundStartDate: number;
	prepareTimeStartDate: number;
	serverCurrentDate: number;
}

export interface IRoundInitialState {
	status: {
		isRoundGoing: boolean;
		isPostRoundGoing: boolean;
		isTimeoutGoing: boolean;
	};
	time: {
		timeoutPercent: number;
	};
	roundStarted: {
		hash: string;
	};
	animation: {
		prevProgressIndex: number;
		nextProgressIndex: number;
	};
	players: Array<IPlayer>;
	coefficientHistory: Array<number>;
	totalCash: number;
	serverRoundData: {
		serverRoundStartDate: number;
		serverTimeoutStartDate: number;
		roundPassedTime: number;
		preparePassedTime: number;
		currentCoeff: number;
		totalPreparingTime: number;
	};
	currentCoefficient: number;
	serverClientTimeDifference: number;
	activeBet: Pick<IBetInitialState, 'cash'> | null;
}

export type SetCurrentRatioPA = PA<IRoundInitialState['currentCoefficient']>;
export type AddPlayerPA = PA<IPlayer>;
export type SetPlayersPA = PA<IRoundInitialState['players']>;
export type SetTotalCashPA = PA<IRoundInitialState['totalCash']>;
export type StartRoundPA = PA<{
	hash: IRoundInitialState['roundStarted']['hash'];
	serverRoundStartDate: IRoundInitialState['serverRoundData']['serverRoundStartDate'];
}>;
export type FinishRoundPA = PA<IRoundInitialState['currentCoefficient']>;
export type SetTimeoutPercentPA = PA<IRoundInitialState['time']['timeoutPercent']>;
export type InitRoundPA = PA<{
	serverRoundStartDate: IRoundInitialState['serverRoundData']['serverRoundStartDate'];
	serverTimeoutStartDate: IRoundInitialState['serverRoundData']['serverTimeoutStartDate'];
	hash: IRoundInitialState['roundStarted']['hash'];
	players: IRoundInitialState['players'];
	isRoundGoing: IRoundInitialState['status']['isRoundGoing'];
	isTimeoutGoing: IRoundInitialState['status']['isTimeoutGoing'];
	isPostRoundGoing: IRoundInitialState['status']['isPostRoundGoing'];
	totalPreparingTime: IRoundInitialState['serverRoundData']['totalPreparingTime'];
	totalCash: IRoundInitialState['totalCash'];
	serverClientTimeDifference: IRoundInitialState['serverClientTimeDifference'];
}>;
export type SetLosersPA = PA<Array<number>>;
export type SetWinnerPA = PA<IPlayer>;
export type SetCoefficientsHistoryPA = PA<IRoundInitialState['coefficientHistory']>;
export type SetAnimationProgressIndexesPA = PA<IRoundInitialState['animation']>;
export type SetServerTimeoutStartDatePA = PA<
	IRoundInitialState['serverRoundData']['serverTimeoutStartDate']
>;
export type SetServerRoundPassedTimePA = PA<
	IRoundInitialState['serverRoundData']['roundPassedTime']
>;
export type SetTotalPreparingTimePA = PA<
	IRoundInitialState['serverRoundData']['totalPreparingTime']
>;
export type SetActiveBetPA = PA<IRoundInitialState['activeBet']>;

export enum BetModalType {
	didBet = 'didBet',
	won = 'won'
}

// Bet
export interface IBetInitialState {
	cash: string;
	coefficient: string;
	won: number;
	autoStopMode: 'on' | 'off';
	betModalType: BetModalType | null;
	isWaitingBetStatus: boolean;
}

export type SetCashBetValuePA = PA<IBetInitialState['cash']>;
export type SetCoefficientBetValuePA = PA<IBetInitialState['coefficient']>;
export type SwitchAutoStopModePA = PA<IBetInitialState['autoStopMode']>;
export type SetBetModalTypePA = PA<IBetInitialState['betModalType']>;
export type SetWonValuePA = PA<IBetInitialState['won']>;
export type SetIsWaitingBetStatusPA = PA<IBetInitialState['isWaitingBetStatus']>;

// User
export enum Settings {
	username = 'username',
	email = 'email',
	phone = 'phone',
	password = 'password',
	locale = 'locale'
}

export enum Countries {
	rus = 'RUS',
	uzb = 'UZB',
	bra = 'BRA',
	bgd = 'BGD'
}

export enum Currencies {
	inr = 'INR',
	bdt = 'BDT',
	brl = 'BRL'
}

export enum Roles {
	admin = 'admin',
	player = 'player'
}

export interface IUserInitialState {
	id: number;
	username: string;
	email: string;
	image: string;
	balance: number;
	bonusBalance: number;
	createdAt: string;
	userLevelId: number;
	role: Roles;
	currency: Currencies;
	hasPassword: boolean;
	langCode: Languages;
	isVerified: boolean;
	isPremium: boolean;
	phone: string;
	referralCode: string;
	successInvoiceCount: number;
	userLevelSum: number;
	telegramChannelBotLink: string;
	isPhoneVerified: boolean;
	passwordChangeAt: string;
	isWithdrawEnabled: boolean;
	isReferralFroze: boolean;
	locale: string;
	// вне получения с сервера
	editingSettings: Array<Settings>;
	updMeta: Record<Settings, string> | {};
	socialsLinked: Record<Socials, boolean>;
	userLevels: Array<UserLevelObj>;

	bonusBalanceEnableValue: number;
	bonusBalanceMaxValue: number;
	bonusBalanceWager: number;
	bonusUpdateDate: string | null;
	locationData: UserLocationDataType | null;

	depositWager: number;

	botTelegramId?: string;
}

export type UserLocationDataType = {
	currency: string;
	countryCode: string;
	timezone: string;
	phoneCode: string;
};

export type UserLevelObj = {
	cashbackMaxSum: number;
	cashbackPayout: number;
	cashbackPercentage: number;
	cashbackWager: number;
	currency: Currencies;
	id: number;
	level: number;
	name: string;
	referralBonus: number;
	wager: number;
	weeklyCashback: number;
	weeklyCashbackPremium: number;
};

export type InitUserPA = PA<
	Omit<IUserInitialState, 'editingSettings' | 'updMeta' | 'socialsLinked'>
>;
export type SetBalancePA = PA<IUserInitialState['balance']>;
export type SetLocationDataPA = PA<IUserInitialState['locationData']>;
export type SetUserLevelSumPA = PA<IUserInitialState['userLevelSum']>;
export type SetBonusBalancePA = PA<IUserInitialState['bonusBalance']>;
export type SetBonusBalanceWagerPA = PA<IUserInitialState['bonusBalanceWager']>;
export type SetCreatedAtPA = PA<IUserInitialState['createdAt']>;
export type SetUserLevleIdPA = PA<IUserInitialState['userLevelId']>;
export type SetUserLevels = PA<IUserInitialState['userLevels']>;
export type SetDepositWagerPA = PA<IUserInitialState['depositWager']>;
export type SetBotTelegramIdPA = PA<IUserInitialState['botTelegramId']>;

export type SetEditingSettingsPA = PA<{ setting: Settings; rule: 'add' | 'remove' }>;
export type SetUpdMetaPA = PA<{ setting: Settings; value: string }>;
export type SetActiveCurrencyPA = PA<IUserInitialState['currency']>;
export type SetLangCodePA = PA<IUserInitialState['langCode']>;
export type SetNewCurrentUserImagePA = PA<IUserInitialState['image']>;
export type SetSocialsLinkedPA = PA<{ social: Socials; isLinked: boolean }>;
export type SetReferralCodePA = PA<IUserInitialState['referralCode']>;
export type SetPremiumStatusPA = PA<IUserInitialState['isPremium']>;

// Payment

export enum PaymentType {
	invoice = 'invoice',
	withdrawal = 'withdrawal',
	refund = 'refund',
	withdraw_invoice = 'withdraw_invoice',
	withdraw = 'withdraw',
	'rmw-bonus' = 'rmw-bonus',
	'promo_money' = 'promo_money',
	'cashback' = 'cashback',
	'task' = 'task',
	'referral' = 'referral',
	'freespin_win' = 'freespin_win',
	'freeze_balance_update' = 'freeze_balance_update',
	'transfer_bonus' = 'transfer_bonus',
	'bonus_expire' = 'bonus_expire',
	'level_upgrade_bonus' = 'level_upgrade_bonus',
	'bonus_balance' = 'bonus_balance'
}

export enum PaymentFlows {
	flow_1 = 'flow_1',
	flow_2 = 'flow_2',
	native_confirm = 'native_confirm',
	flow_nagad = 'flow_nagad'
}

export enum PaymentMethodType {
	fiat = 'fiat',
	crypto = 'crypto'
}

export enum PaymentUIType {
	direct = 'direct',
	iframe = 'iframe',
	redirect = 'redirect',
	native = 'native',
	native_confirm = 'native_confirm'
}

export enum PaymentTypeBE {
	post_redirect = 'POST_REDIRECT'
}

export enum PaymentResultsType {
	success = 'success',
	error = 'error'
}

export enum PromocodeStatusResponse {
	notExist = 'notExist',
	// alreadyUsed = 'alreadyUsed',
	success = 'success'
}

export enum CryptoCurrency {
	ERC20 = 'Tether ERC20',
	TRC20 = 'Tether TRC20',
	BNB = 'BNB',
	ETH = 'ETH',
	LTC = 'LTC',
	BTC = 'BTC',
	TRX = 'TRX'
}

export enum CryptoCurrencyKey {
	ERC20 = 'USDT',
	TRC20 = 'USDT',
	BNB = 'BNB',
	ETH = 'ETH',
	LTC = 'LTC',
	BTC = 'BTC',
	TRX = 'TRX'
}

export enum PaymentProvider {
	payForm = 'pay-form',
	spicyCash = 'spicy-cash',
	pspCube = 'psp-cube',
	cryptomus = 'cryptomus',
	octopay = 'octopay'
}

export enum CryptoCurrencyNetwork {
	ERC20 = 'eth',
	TRC20 = 'tron',
	BNB = 'bsc',
	ETH = 'eth',
	LTC = 'ltc',
	BTC = 'btc',
	TRX = 'tron'
}

export interface IP2pInvoiceDetails {
	receiverCardNumber: string;
	requiredAmount: string;
	expiresAt: number;
}

export interface ICryptoInvoiceDetails {
	expiredAt: number;
	currency: CryptoCurrencyKey;
	address: string;
	amount: string;
}

export enum PaymentServiceMethod {
	withdraw = 'withdraw',
	invoice = 'invoice',
	withdraw_invoice = 'withdraw_invoice'
}

export interface IPaymentMethod {
	id: number;
	name: string;
	provider: PaymentProvider;
	responseType: PaymentUIType;
	type: PaymentMethodType;
	image: string;
	categories: Array<string>;
	settings: IPaymentMethodSettings;
	method: PaymentServiceMethod;
}

export interface Fields {
	field: string;
	title: string;
	placeholder?: string;
	validator?: {
		regex?: string;
		errorMessage?: string;
	};
}

export interface IPaymentMethodSettings {
	maxInvoice: number;
	minInvoice: number;
	maxWithdraw: number;
	minWithdraw: number;
	presets: Array<{ amount: number; description: string } | number>;
	description: string;
	videoUrl: string;
	withdrawFixedCommission: number;
	withdrawPercentageCommission: number;
	fields?: Fields[];
	descriptionUnderButton?: string;
}

export interface IPaymentInitialState {
	paymentType: PaymentType | null;
	paymentMethodType: PaymentMethodType | null;
	invoiceValue: string;
	invoiceActiveCryptoCurrency: {
		name: CryptoCurrency;
		key: CryptoCurrencyKey;
		network: CryptoCurrencyNetwork;
	};
	withdrawalValue: string;
	withdrawalCardNumber: string;
	withdrawalCryptoAddress: string;
	withdrawalActiveCryptoCurrency: {
		name: CryptoCurrency;
		key: CryptoCurrencyKey;
		network: CryptoCurrencyNetwork;
	};
	withdrawalCommission: { commission: number; payout: number; isPending: boolean };
	isRequestToWithdrawalSuccess: boolean;
	isPaymentProcessing: boolean;
	p2p: IP2pInvoiceDetails | null;
	cryptoInvoiceDetails: ICryptoInvoiceDetails | null;
	iframePayment: {
		link: string;
	} | null;
	selectedTypePaymentMethods: Array<IPaymentMethod>;
	paymentMethodsWithdraw: Array<IPaymentMethod>;
	paymentMethodsInvoice: Array<IPaymentMethod>;
	selectedPaymentMethodId: IPaymentMethod['id'] | null;
	userConfirmedPayment: boolean;
	qrPaymentInfo: {
		step: 1 | 2;
		qr: string;
		id: number | string;
	};
}

export type InitPaymentPA = PA<IPaymentInitialState['paymentType']>;
export type SetPaymentMethodTypePA = PA<IPaymentInitialState['paymentMethodType']>;
export type SetQrPaymentInfoTypePA = PA<IPaymentInitialState['qrPaymentInfo']>;
export type SetUserConfirmedPaymentPA = PA<IPaymentInitialState['userConfirmedPayment']>;

export type SetInvoiceValuePA = PA<IPaymentInitialState['invoiceValue']>;
export type SetInvoiceActiveCryptoCurrencyPA = PA<
	IPaymentInitialState['invoiceActiveCryptoCurrency']
>;
export type SetWithdrawalValuePA = PA<IPaymentInitialState['withdrawalValue']>;
export type SetWithdrawalCardNumberPA = PA<IPaymentInitialState['withdrawalCardNumber']>;
export type SetWithdrawalCryptoAddressPA = PA<
	IPaymentInitialState['withdrawalCryptoAddress']
>;
export type SetWithdrawalActiveCryptoCurrencyPA = PA<
	IPaymentInitialState['withdrawalActiveCryptoCurrency']
>;
export type SetWithdrawalCommissionPA = PA<
	Omit<IPaymentInitialState['withdrawalCommission'], 'isPending'>
>;
export type SetWithdrawalCommissionPendingPA = PA<
	IPaymentInitialState['withdrawalCommission']['isPending']
>;
export type SetPaymentProcessingStatusPA = PA<
	IPaymentInitialState['isPaymentProcessing']
>;
export type InitP2pInvoicePA = PA<IPaymentInitialState['p2p']>;
export type InitCryptoInvoicePA = PA<IPaymentInitialState['cryptoInvoiceDetails']>;
export type SetIframePaymentPA = PA<IPaymentInitialState['iframePayment']>;
export type SetPaymentMethodsInvoicePA = PA<
	IPaymentInitialState['paymentMethodsInvoice']
>;
export type SetPaymentMethodsWithdrawPA = PA<
	IPaymentInitialState['paymentMethodsWithdraw']
>;
export type SetSelectedPaymentMethodIdPA = PA<
	IPaymentInitialState['selectedPaymentMethodId']
>;

// Statistics

export enum GameType {
	local = 'local',
	provider = 'provider'
}

export enum LastWinStatisticsType {
	all = 'all',
	highAmount = 'highAmount',
	highCoeff = 'highCoeff'
}

export interface ILastWinUser {
	gameName: string;
	userName: string;
	gameType: GameType;
	gameKey: string;
	time: string;
	bet: number;
	coef: number;
	amount: number;
	winnerTypes: Array<LastWinStatisticsType>;
}

export type LastWinsStatistics = Record<LastWinStatisticsType, Array<ILastWinUser>>;

export type RecentBigWin = {
	amount: number;
	playerName: string;
	gameKey: string;
	gameImage: string;
};

export interface ICurrentUserTransaction {
	id: number;
	createdAt: Date;
	amount: number;
	bonusBalance: number;
	type: PaymentType;
	status: 'Done' | 'Processing' | 'Cancelled' | 'Error';
}

export interface ICurrentUserBet {
	id: number;
	createdAt: Date;
	gameName: string;
	gameKey: string;
	amount: number;
	coefficient?: number;
	winAmount?: number;
}

export interface ICommonStatisticsInitialState {
	onlineUserQty: number;
	totalUserQty: number;
	totalGameQty: number;
	todayWonGameQty: number;
	lastWinsStatistics: LastWinsStatistics;
	recentBigWins: Array<RecentBigWin>;
	recentBigWin: RecentBigWin | null;
	currentUserTransactions: Array<ICurrentUserTransaction>;
	currentUserBets: Array<ICurrentUserBet>;
	randomOnlineUserQtyAdding: number;
	randomTotalUserQty: number;
}

export type AddLastWinStatisticsPA = PA<ILastWinUser>;
export type SetRecentBigWinsPA = PA<ICommonStatisticsInitialState['recentBigWins']>;
export type SetRecentBigWinPA = PA<RecentBigWin>;
export type SetCurrentUserTransactionsPA = PA<
	ICommonStatisticsInitialState['currentUserTransactions']
>;
export type SetCurrentUserBetsPA = PA<ICommonStatisticsInitialState['currentUserBets']>;

// Interface Volume

export interface IInterfaceVolume {
	userHasInteracted: boolean;
	isMuted: boolean;
	volume: number;
	oldVolume: number;
}

export type SetVolumePA = PA<number>;
export type SetOldVolumePA = PA<number>;
export type SetMutedPA = PA<boolean>;

// Common UI

export enum ModalType {
	auth = 'auth',
	logout = 'logout',
	bonusWidgetModal = 'bonusWidgetModal',
	bonusMoneyTooltip = 'bonusMoneyTooltip',
	signIn = 'signIn',
	gamesSearcher = 'gamesSearcher',
	bonusCancel = 'bonusCancel',
	promocode = 'promocode',
	regionChanger = 'regionChanger',
	resetPass = 'resetPass',
	cashback = 'cashback',
	transAndBets = 'transAndBets',
	profileSettings = 'profileSettings',
	levelsTable = 'levelsTable',
	cashbackRulesTable = 'cashbackRulesTable',
	information = 'information',
	renewPassword = 'renewPassword',

	referralAwards = 'referralAwards',
	referral = 'referral',

	politicsTerms = 'politicsTerms',
	politicsTermsAndConditions = 'politicsTermsAndConditions',
	politicsSelfPolicy = 'politicsSelfPolicy',
	politicsPrivacyPolicy = 'politicsPrivacyPolicy',
	politicsAmlKycPolicy = 'politicsAmlKycPolicy',

	wageringGames = 'wageringGames',

	setPassword = 'setPassword',

	withdrawalBlocked = 'withdrawalBlocked',
	withdrawalInfo = 'withdrawalInfo',
	paymentQR = 'paymentQR',
	license = 'license',

	bonusDepositModal = 'bonusDepositModal',
	freespinsModal = 'freespinsModal',
	promoBonusModal = 'promoBonusModal',
	cashbackModal = 'cashbackModal',
	levelUpModal = 'levelUpModal',
	tgBonusModal = 'tgBonusModal',
	referralBonusModal = 'referralBonusModal',
	appInstallationBonusModal = 'appInstallationBonusModal'
}

export interface ICommonUIInitialState {
	isGamesSearching: boolean;
	isFullScreenGaming: boolean;
	isSidebarOpened: boolean;
	isSidebarDesktopOpened: boolean;
	activeLastWinsStatisticsName: string;
	modalType: ModalType | null;
	modalSocialBonusTaskType: SocialBonusTaskType | null;
	isMobile: boolean;
	isTablet: boolean;
	isLiveChatOpened: boolean;
	mobileHeight: number;
	is404Page: boolean;
	isSidebarTabletOpened: boolean;
	isUpdateGameProviders: boolean;
	isUpdateGamesList: boolean;
	isTelegram: boolean;
	isApp: boolean;
	isAndroidBanner: boolean;
}

export type SetIsGamesSearchingPA = PA<ICommonUIInitialState['isGamesSearching']>;
export type SetIsTelegramPA = PA<PlatfromQueryE>;

export type SetIsUpdateGameProviders = PA<ICommonUIInitialState['isUpdateGameProviders']>;
export type SetIsAndroidBanner = PA<ICommonUIInitialState['isAndroidBanner']>;
export type SetIsUpdateGamesList = PA<ICommonUIInitialState['isUpdateGamesList']>;

export type SetIsFullScreenGamingPA = PA<ICommonUIInitialState['isFullScreenGaming']>;
export type SetIsSidebarOpenedPA = PA<ICommonUIInitialState['isSidebarOpened']>;
export type SetIsSidebarHiddenPA = PA<ICommonUIInitialState['isSidebarOpened']>;

export type SetActiveLastWinsStatisticsNamePA = PA<
	ICommonUIInitialState['activeLastWinsStatisticsName']
>;
export type SetModalTypePA = PA<ICommonUIInitialState['modalType']>;
export type SetModalSocialBonusTaskTypePA = PA<
	ICommonUIInitialState['modalSocialBonusTaskType']
>;
export type SetIsMobilePA = PA<ICommonUIInitialState['isMobile']>;
export type SetIsTabletPA = PA<ICommonUIInitialState['isTablet']>;
export type SetMobileHeightPA = PA<ICommonUIInitialState['mobileHeight']>;
export type SetIsLiveChatOpenedPA = PA<ICommonUIInitialState['isLiveChatOpened']>;

// bonus

export enum UserDepositBonusStatus {
	new = 'new',
	in_progress = 'in_progress',
	used = 'used'
}

export enum SocialBonusTaskType {
	telegram_ref_bot = 'telegram_ref_bot'
}

export interface IUserLevel {
	id: number;
	name: string;
	level: number;
	wager: number;
	dailyRake: number;
	weeklyRake: number;
	monthlyRake: number;
	referralBonus: number;
}

export interface IUserDepositBonus {
	id: number;
	title: string;
	maxBonus: number;
	bonusPaymentPercentage: number;
	wagerCoefficient: number;
	expirationMinutes: number;
}

export interface IRMWBonus {
	createdAt: string;
	currency: string;
	id: number;
	isActive: boolean;
	title: string;
	type: string;
	config: {
		bonusType: string;
		bonusTypeWager: number;
		depositNumber: number;
		gameId: number;
		depositMap?: Array<{ depositAmount: number; freespinAmount: number }>;
		bonusPaymentPercentage: number;

		bonus?: { wager: number; amount: number };

		freespin?: { wager: number; amount: number; gameId: number };

		userLevel?: number;
	};
}

export interface IBonusBalance {
	id: number;
	createdAt: string;
	userId: number;
	type: string;
	status: string;
	bonusBalance: number;
	bonusBalanceWager: number;
	bonusBalanceTransferLimit: number;
	currency: string;
	activatedAt: string | null;
	duration: number;
	expiresAt: string | null;
	isVisited: boolean;
	transactionAmount: number | null;
}

export interface ISocialBonusTask {
	task: {
		id: number;
		type: SocialBonusTaskType;
		config: {
			postId?: number;
			groupId?: number;
			channelId?: string;
		};
	};
	isEligible: boolean;
	isDone: boolean;
	extraData?: {
		isProfileOpen: boolean;
		isRepost: boolean;
		isSubscribed: boolean;
	};
}

export interface IReferralReward {
	username: string;
	level: string;
	amount: number;
	userId: number;
	levelId: number;
}

export interface IBonusInitialState {
	allLevels: Array<IUserLevel>;
	allRMWBonus: Array<IRMWBonus>;
	// TODOрефакт.
	tasks: {
		tg: {
			accessCode: string;
			botUrl: string;
		};
	};
	socialBonusTasks: Array<ISocialBonusTask>;
	promocode: {
		status: PromocodeStatusResponse | null;
		isForbiddenForUsing: boolean;
		usingForbiddenTime: number | undefined;
		inputValue: string;
	};
	referral: {
		link: string;
		totalUserQty: number;
		pendingRewardsAmount: number;
		totalRewardsSum: number | null;
		rewards: Array<IReferralReward>;
	};
	premiumSubscriptionConditions: {
		fee: number;
		reward: number;
	} | null;
	freeSpinsInfo: Array<{
		amount: number;
		gameId: number;
		gameKey: string;
		winAmount: number;
		id: number;
		isVisited: boolean;
	}> | null;
	currentWeekCashbackAmount: number;

	bonusGameOpen: {
		isFreespinGame: boolean;
		isFreezeBalancePlay: boolean;
		route: string;
	} | null;

	bonusBalances: Array<IBonusBalance>;
	bonusBalancesHistory: {
		count: number;
		data: Array<IBonusBalance>;
		page: number;
		pageCount: number;
		total: number;
	};
}

export type SetAllLevelsPA = PA<IBonusInitialState['allLevels']>;
export type SetBonusGameOpenPA = PA<IBonusInitialState['bonusGameOpen']>;

export type SetAllRMWBonusPA = PA<IBonusInitialState['allRMWBonus']>;
export type SetTelegramCodePA = PA<IBonusInitialState['tasks']['tg']>;
export type SetSocialBonusTask = PA<IBonusInitialState['socialBonusTasks']>;
export type SetPromocodeStatusPA = PA<IBonusInitialState['promocode']['status']>;
export type SetPromocodeInputValuePA = PA<IBonusInitialState['promocode']['inputValue']>;
export type SetPromocodeUsingBanPA = PA<
	Pick<IBonusInitialState['promocode'], 'isForbiddenForUsing' | 'usingForbiddenTime'>
>;
export type SetReferralLinkPA = PA<IBonusInitialState['referral']['link']>;
export type SetReferralRewardsPA = PA<IBonusInitialState['referral']['rewards']>;
export type SetReferralsQtyAndPendingRewardsPA = PA<
	Pick<
		IBonusInitialState['referral'],
		'totalUserQty' | 'pendingRewardsAmount' | 'totalRewardsSum'
	>
>;
export type SetPremiumSubscriptionConditionsPA = PA<
	IBonusInitialState['premiumSubscriptionConditions']
>;
export type SetFreeSpinsInfoPA = PA<IBonusInitialState['freeSpinsInfo']>;
export type SetCashbackInfoPA = PA<IBonusInitialState['currentWeekCashbackAmount']>;

export type SetBonusBalancesPA = PA<IBonusInitialState['bonusBalances']>;
export type SetBonusBalancesHistoryPA = PA<IBonusInitialState['bonusBalancesHistory']>;
export type DeleteBonusBalancesPA = PA<number>;

// game-provider

export enum ProviderGamesType {
	providerGames = 'providerGames',
	foundProviderGames = 'foundProviderGames',
	lastSeenGames = 'lastSeenGames',
	pageProviderGames = 'pageProviderGames',
	favoriteProviderGames = 'favoriteProviderGames'
}

export interface IProviderGame {
	id: number;
	key: string;
	name: string;
	providerId: number;
	providerName: string;
	categories: Array<number>;
	technology: 'HTML5' | 'Flash';
	image: string | null;
	isFavourite: boolean;
	isDemo: boolean;
	isFreezeBalancePlay: boolean;
}

export interface IGameCategory {
	id: number;
	key: keyof typeof CategoriesKeys;
	name: string;
	gamesCount: number;
	order: number;
}

export interface IProvider {
	id: number;
	key: string;
	name: string;
	image: string;
	gameQty: number;
}

export interface IGameProviderInitialState {
	providerGames: Array<IProviderGame>;
	lastSeenGames: Array<IProviderGame>;
	foundProviderGames: Array<IProviderGame>;
	pageProviderGames: Array<IProviderGame>;
	favoriteProviderGames: Array<IProviderGame>;
	localGames: Array<IProviderGame>;
	providers: Array<IProvider>;
	gameCategories: Array<IGameCategory>;
	gameCount: number;
	// TODOфиксануть лишние поля
	currentInitedGame: {
		name: string;
		iframeSrc: string;
		clientRoute: string;
		isFavorite?: boolean;
		gameId?: number;
		isFreezeBalancePlay?: boolean;
	} | null;
	currentGameCategory: {
		id: number;
		name: string;
		totalGameQty: number;
	} | null;
	currentGameProvider: {
		id: number;
		name: string;
		totalGameQty: number;
	} | null;
}

export type SetProviderGamesPA = PA<{
	providerGames: Array<IProviderGame>;
	providerGamesType: ProviderGamesType;
}>;

export type SetProviderFavoriteGamesPA = PA<{
	providerGamesType: ProviderGamesType;
	gameId: number;
}>;

export type SetLocalGamesPA = PA<IGameProviderInitialState['localGames']>;
export type SetProvidersPA = PA<IGameProviderInitialState['providers']>;
export type SetGameCategoriesPA = PA<IGameProviderInitialState['gameCategories']>;
export type SetGameCountPA = PA<IGameProviderInitialState['gameCount']>;
export type SetCurrentGameCategoryPA = PA<
	IGameProviderInitialState['currentGameCategory']
>;
export type SetCurrentGameProviderPA = PA<
	IGameProviderInitialState['currentGameProvider']
>;
export type SetCurrentProviderGamePA = PA<IGameProviderInitialState['currentInitedGame']>;

// common
export type StoreInner = {
	auth: IAuthInitialState;
	user: IUserInitialState;
	payment: IPaymentInitialState;
	commonStatistics: ICommonStatisticsInitialState;
	commonUI: ICommonUIInitialState;
	bonus: IBonusInitialState;
	gameProvider: IGameProviderInitialState;
};

export type InvoicePaymentResponseDataType = {
	isSuccess: boolean;
	link: string;
	provider: PaymentProvider;
	id: string;
} & {
	isSuccess: boolean;
	qr: string;
	provider: PaymentProvider;
	id: string;
} & {
	isSuccess: boolean;
	cardNumber: string;
	sum: number;
	id: string;
} & {
	isSuccess: boolean;
	provider: PaymentProvider;
	walletForPay: string;
	invoiceId: string;
	id: string;
} & {
	id: string;
	isSuccess: boolean;
	provider: PaymentProvider;
	target_card_number: string;
	valid_till: number;
} & {
	id: string;
	isSuccess: boolean;
	provider: PaymentProvider;
	link: string;
	type: PaymentTypeBE;
	fields: Array<{ name: string; value: string }>;
};
