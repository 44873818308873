import React, { ReactNode, useState } from 'react';
import cn from 'classnames';

import s from './style.module.scss';

import { ShevronSvg } from '@components/svg';

interface AccordionItemProps {
	title: ReactNode;
	content: ReactNode;
	image?: ReactNode;
}

export const AccordionItem: React.FC<AccordionItemProps> = ({
	title,
	content,
	image
}) => {
	const [isActive, setIsActive] = useState(false);

	return (
		<div className={cn(s.win_money, s.win_money_border)}>
			{image || null}
			<div
				className={cn(s.win_money_title, { [s.withoutPadding]: !image })}
				onClick={() => setIsActive(!isActive)}
			>
				<div>{title}</div>
				<div className={cn(s.arrow, { [s.opened]: isActive })}>
					<ShevronSvg />
				</div>
			</div>
			{isActive && (
				<div className={cn(s.win_money_descr, { [s.withoutPadding]: !image })}>
					{content}
				</div>
			)}
		</div>
	);
};
