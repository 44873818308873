import React from 'react';
import { Currencies, SocialLoginMethodTypesE, EUrlQueries } from '@types';
import { GoogleIcon } from '@components/svg';
import { getStringQueryFromUrl } from '@utils';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';
import { SUPPORTED_LANGS } from '@config/global';
import { userSelectors } from '@store/reducers/user/selectors';
import { useSelector } from 'react-redux';
import { useLocales } from '@lib/hooks';
import { isNull } from 'lodash';
import QueryString from 'qs';
import s from './style.module.scss';
import { useTranslation } from 'react-i18next';

type PropsTypes = {
	currency: Currencies | string;
	returnUrl?: string;
	analyticsHandler?: (method: SocialLoginMethodTypesE) => void;
};

export const SocialLoginBtns = (props: PropsTypes) => {
	const { currency, returnUrl, analyticsHandler } = props;

	const { localizeText } = useLocales({
		path: 'components/common/modals',
		node: 'AuthModal'
	});

	const { i18n } = useTranslation();

	const { locationData } = useSelector(userSelectors.userInfo);
	const { isApp } = useSelector(commonUISelectors.commonUIInfo);

	const tgIdQuery = getStringQueryFromUrl(EUrlQueries.tgId);
	const refQuery = getStringQueryFromUrl(EUrlQueries.ref);
	const partnerIdQuery = getStringQueryFromUrl(EUrlQueries.partnerId);

	const queryParams = {
		currency: currency,
		timezone: locationData?.timezone,
		ref: !isNull(refQuery) ? refQuery : undefined,
		tgId: !isNull(tgIdQuery) ? +tgIdQuery : undefined,
		partnerId: !isNull(partnerIdQuery) ? partnerIdQuery : undefined,
		locale: SUPPORTED_LANGS?.includes(i18n?.language) ? i18n?.language : 'en',
		returnUrl: returnUrl,
		isApp: isApp
	};

	const queryString = QueryString.stringify(queryParams);

	const baseGoogleURL = `${process.env.REACT_APP_API_URL}/auth/google?${queryString}`;

	const redirectMethodTypes: Array<{
		text: string;
		type: SocialLoginMethodTypesE;
		url: string;
		icon: JSX.Element;
	}> = [
		{
			text: 'Google',
			type: SocialLoginMethodTypesE?.google,
			url: baseGoogleURL,
			icon: <GoogleIcon />
		}
	];

	return (
		<>
			<div className={s.redirectButtons}>
				{redirectMethodTypes.map((method) => (
					<a
						key={method?.type}
						href={method.url}
						className={
							method.type === SocialLoginMethodTypesE.google
								? s.redirectButtons_googleButton
								: s.redirectButtons_telegramButton
						}
						onClick={() => analyticsHandler && analyticsHandler(method.type)}
					>
						{method.icon}
						<div className={s.redirectButtons_text}>{method.text}</div>
					</a>
				))}
			</div>

			<div className={s.dividerContainer}>
				<div className={s.dividerContainer_dividerLeft}></div>
				<div className={s.dividerContainer_text}>{localizeText('divider_or')}</div>
				<div className={s.dividerContainer_dividerRight}></div>
			</div>
		</>
	);
};
