import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocales, useUrlLang } from '@lib/hooks';
import { setAuthType } from '@store/reducers/auth/dispathcers';
import { authSelectors } from '@store/reducers/auth/selectors';
import { setModalType } from '@store/reducers/common-ui/dispatchers';
import {
	AnalyticsEventType,
	AuthType,
	ButtonType,
	IProviderGame,
	ModalType,
	ProviderGamesType
} from '@types';
import { Button } from '../button';
import { isTapX, isTopXGo } from '@config/global';
import { userSelectors } from '@store/reducers/user/selectors';
import { pathsConfig } from '@config/paths';
import { bonusSelectors } from '@store/reducers/bonus/selectors';
import { setBonusGameOpen } from '@store/reducers/bonus/dispatchers';
import { renderGameUrl, sendAnalyticsHandler } from '@utils';
import s from './style.module.scss';
import cn from 'classnames';
import { BackgroundImageWithFadeIn } from './parts';
import { FavoriteSelected, FavoriteSvg } from '@components/svg';
import { Ripple } from '../ripple-animation';
import { deleteFavoriteGames, setFavoriteGames } from '@lib/api/game-provider';
import { setCurrentInitedGame } from '@store/reducers/game-provider/dispatchers';
import { gameProviderSelectors } from '@store/reducers/game-provider/selectors';
import { commonUISelectors } from '@store/reducers/common-ui/selectors';

type ProviderGameCardP = {
	id: number;
	name: string;
	route: string;
	img: string | null;
	providerName: string;
	className?: string;
	onlyImage?: boolean;
	isFavorite?: boolean;
	isDemo: boolean;
	providerGamesType: ProviderGamesType;
	setLoadedGames?: React.Dispatch<React.SetStateAction<IProviderGame[]>>;
	isCloseModal?: boolean;
	isFreezeBalancePlay: boolean;
	filterProviderName?: string | null;
	filterCategoryName?: string | null;
	onClose?: () => void;
};

export const ProviderGameCard = (props: ProviderGameCardP) => {
	const {
		img,
		route,
		name,
		isDemo,
		isCloseModal,
		isFreezeBalancePlay,
		providerName,
		isFavorite,
		id,
		filterProviderName,
		filterCategoryName,
		onClose,
		className = ''
	} = props;
	const { isAuthed } = useSelector(authSelectors.authInfo);
	const { balance, bonusBalanceEnableValue, bonusBalance } = useSelector(
		userSelectors.userInfo
	);
	const { freeSpinsInfo } = useSelector(bonusSelectors.bonusInfo);
	const { currentInitedGame } = useSelector(gameProviderSelectors.gameProviderInfo);
	const { isMobile } = useSelector(commonUISelectors.commonUIInfo);

	const navigate = useNavigate();
	const { langUrlPrefix } = useUrlLang();

	const { localizeText } = useLocales({
		path: 'components/common/elements',
		node: 'ProviderGameCard'
	});

	const croppedName = isMobile && name.length > 16 ? `${name.slice(0, 16)}...` : name;

	const openAuthModal = () => {
		setModalType(ModalType.auth);
		setAuthType(AuthType.signUp);
	};

	const startFullPlay = () => {
		sendAnalyticsHandler(AnalyticsEventType?.click_card_play, {
			card_name: name,
			card_section: 'casino',
			card_filter: filterCategoryName,
			card_provider: filterProviderName
		});

		if (!isAuthed) {
			openAuthModal();

			return;
		}

		const isFreespinGame = freeSpinsInfo?.find((g) => g?.gameKey === route);

		isCloseModal && setModalType(null);
		onClose && onClose();

		if (isFreespinGame) {
			setBonusGameOpen({
				isFreespinGame: !!isFreespinGame,
				isFreezeBalancePlay,
				route
			});

			return;
		}

		if (balance <= bonusBalanceEnableValue && bonusBalance <= bonusBalanceEnableValue) {
			navigate(langUrlPrefix + pathsConfig.payment.link);

			return;
		}

		if (balance > bonusBalanceEnableValue) {
			navigate(langUrlPrefix + renderGameUrl(route));

			return;
		}

		if (balance <= bonusBalanceEnableValue && bonusBalance >= bonusBalanceEnableValue) {
			if (isFreezeBalancePlay && !isFreespinGame) {
				navigate(langUrlPrefix + renderGameUrl(route));

				return;
			}

			if (!isFreezeBalancePlay && !isFreespinGame) {
				navigate(langUrlPrefix + pathsConfig.payment.link);

				return;
			}
		}
	};

	const startDemoPLay = () => {
		sendAnalyticsHandler(AnalyticsEventType?.click_card_demo, {
			card_name: name,
			card_section: 'casino',
			card_filter: filterCategoryName,
			card_provider: filterProviderName
		});

		onClose && onClose();
		navigate(langUrlPrefix + renderGameUrl(route), { state: { isDemo: true } });

		isCloseModal && setModalType(null);
	};

	const image =
		!img || img === 'null' || img === 'undefined'
			? '/assets/img/common/game-card-image-default.svg'
			: img;

	const cardClickHandler = () => {
		sendAnalyticsHandler(AnalyticsEventType?.click_card, {
			card_name: name,
			card_section: 'casino',
			card_filter: filterCategoryName,
			card_provider: filterProviderName
		});
	};

	const setFavoriteHandler = async () => {
		if (!isAuthed) {
			setModalType(ModalType.auth);

			return;
		}

		await setFavoriteGames({
			providerGamesType: undefined,
			gameId: id || 0
		}).then(
			() =>
				currentInitedGame &&
				setCurrentInitedGame({
					...currentInitedGame,
					isFavorite: true
				})
		);
	};

	const deleteFavoriteHandler = async () => {
		if (!isAuthed) {
			setModalType(ModalType.auth);

			return;
		}

		await deleteFavoriteGames({
			providerGamesType: undefined,
			gameId: id || 0
		}).then(
			() =>
				currentInitedGame &&
				setCurrentInitedGame({
					...currentInitedGame,
					isFavorite: false
				})
		);
	};

	const handleStartFullPlayWithoutDemo = () => {
		if (!isDemo) {
			startFullPlay();
		}
	};

	return (
		<div
			onClick={cardClickHandler}
			className={cn(s.gameWrapper, s.gameWrapperGrid, className)}
		>
			<div
				className={s.gameCover}
				onClick={handleStartFullPlayWithoutDemo}
			>
				<div className={s.gameInfo}>
					<h3 className={s.gameInfoName}>{name}</h3>
				</div>

				<div className={s.btnsBlock}>
					{!isTopXGo && !isTapX && (
						<Button
							type={ButtonType?.primary}
							rippleAnimation
							handle={startFullPlay}
							className={cn(s.button, s.play)}
							text={localizeText('play')}
						/>
					)}
					{isDemo && (
						<Button
							rippleAnimation
							handle={startDemoPLay}
							className={cn(s.button, s.demo)}
							text={localizeText('demo')}
						/>
					)}
				</div>
			</div>

			<div className={s.front}>
				<BackgroundImageWithFadeIn
					imageUrl={image}
					isDefault={image?.includes('default')}
				/>
			</div>

			{!!isFreezeBalancePlay && <div className={s.bonusGameIndicator}>Bonus game</div>}
			<div className={s.gameInfoWrapper}>
				<span className={s.gameName}>{croppedName}</span>
				<span className={s.providerName}>{providerName}</span>
				<button
					onClick={isFavorite ? deleteFavoriteHandler : setFavoriteHandler}
					className={s.starIcon}
				>
					{isFavorite && <FavoriteSelected />}
					{!isFavorite && <FavoriteSvg />}
					<Ripple />
				</button>
			</div>
		</div>
	);
};
