import { createSlice } from '@reduxjs/toolkit';

import * as reducers from './reducers';

import { IBonusInitialState } from '@types';

const initialState: IBonusInitialState = {
	// TODOесли дописываешь поле, не забудь в редьюсере на отчистку стейта также их добавить
	allLevels: [],
	allRMWBonus: [],
	// TODOрефакт.
	tasks: {
		tg: {
			accessCode: '',
			botUrl: ''
		}
	},
	socialBonusTasks: [],
	promocode: {
		status: null,
		isForbiddenForUsing: false,
		usingForbiddenTime: 0,
		inputValue: ''
	},
	referral: {
		link: '',
		totalUserQty: 0,
		pendingRewardsAmount: 0,
		totalRewardsSum: 0,
		rewards: []
	},
	premiumSubscriptionConditions: null,
	freeSpinsInfo: null,
	currentWeekCashbackAmount: 0,
	bonusGameOpen: null,
	bonusBalances: [],
	bonusBalancesHistory: {
		count: 0,
		data: [],
		page: 0,
		pageCount: 0,
		total: 0
	}
};

export const { actions, reducer } = createSlice({
	name: 'bonus',
	initialState,
	reducers
});
