import { CSSProperties } from 'react';

export enum ButtonType {
	default = 'Default',
	primary = 'Primary',
	secondary = 'Secondary'
}

export type ButtonP = {
	wrapperClass?: string;
	text?: any;
	children?: React.ReactNode;
	type?: ButtonType;
	handle?: Function;
	className?: string;
	isDisabled?: boolean;
	styles?: CSSProperties;
	rippleAnimation?: boolean;
	rippleColor?: string;
	rippleDuration?: number;
	rippleOpacity?: number;
	withoutGlare?: boolean;
	btnType?: 'button' | 'submit' | 'reset' | undefined;
};

export enum MenuSideBarNames {
	bonuces = 'bonuces',
	refferal = 'refferal',
	bets_and_tarns = 'bets_and_tarns',
	promocode = 'promocode',
	casino = 'casino',
	// live_dilers = 'live_dilers',
	// crash_games = 'crash_games',
	// tv_games = 'tv_games',
	sportpoker = 'sportpoker',
	info = 'info',
	settings = 'settings',
	telegram_bonus = 'telegram_bonus',
	cashback = 'cashback',
	levels = 'levels'
}

export enum CategoriesKeys {
	all = 'all',
	tv_games_sport = 'tv_games_sport',
	tv_games_craps = 'tv_games_craps',
	tv_games_card = 'tv_games_card',
	tv_games_wheel = 'tv_games_wheel',
	tv_games_lotto = 'tv_games_lotto',

	live_dealers_other = 'live_dealers_other',
	live_dealers_firtsview = 'live_dealers_firtsview',
	live_dealers_baccarat = 'live_dealers_baccarat',
	live_dealers_poker = 'live_dealers_poker',
	live_dealers_show = 'live_dealers_show',
	live_dealers_blackjack = 'live_dealers_blackjack',
	live_dealers_roulette = 'live_dealers_roulette',
	live_dealers_new = 'live_dealers_new',

	megaways = 'megaways',
	classic = 'classic',
	books = 'books',
	asia = 'asia',
	egypt = 'egypt',
	fantasy = 'fantasy',
	mifology = 'mifology',
	new = 'new',
	bonus = 'bonus',
	jackpot = 'jackpot',
	crash = 'crash',
	card = 'card',
	roulette = 'roulette',
	slots = 'slots',

	quick_games = 'quick_games',
	game_shows = 'game_shows',
	blackjack = 'blackjack',
	live_casino = 'live_casino',
	scratch_cards = 'scratch_cards',
	lottery = 'lottery',
	top_games = 'top_games',
	table_games = 'table_games'
}

export enum BottomMenuKeys {
	casino = 'casino',
	live_dealers = 'live_dealers',
	menu = 'menu',
	crash = 'crash',
	tv_games = 'tv_games'
}

export enum IconsListType {
	angle_top = 'angle_top',
	substract = 'substract',
	angle_bottom = 'angle_bottom',
	comment_new = 'comment_new',
	comment_new_online = 'comment_new_online',
	telegram_new = 'telegram_new',
	cancel = 'cancel',
	back_arrow = 'back_arrow',
	check = 'check',
	first_icon = 'first_icon',
	second_icon = 'second_icon',
	check_icon = 'check_icon',
	close = 'close',
	comment = 'comment',
	copy = 'copy',
	double_arrow = 'double_arrow',
	download_arrow = 'download_arrow',
	edit_pencil = 'edit_pencil',
	error_popup = 'error_popup',
	eye_off = 'eye_off',
	eye_on = 'eye_on',
	favorite = 'favorite',
	favorite_selected = 'favorite_selected',
	full_screen = 'full_screen',
	go_up_arrow = 'go_up_arrow',
	green_check = 'green_check',
	info_warn = 'info_warn',
	info_warn_grey = 'info_warn_grey',
	left_arrow = 'left_arrow',
	left_arrow_swiper = 'left_arrow_swiper',
	levels_green_check = 'levels_green_check',
	lock_fill = 'lock_fill',
	log_out = 'log_out',
	no_favorites = 'no_favorites',
	providers_list = 'providers_list',
	right_arrow_swiper = 'right_arrow_swiper',
	search = 'search',
	selected_payment_method = 'selected_payment_method',
	settings = 'settings',
	shevron = 'shevron',
	shevron_levels = 'shevron_levels',
	success_popup = 'success_popup',
	tap_x_logo = 'tap_x_logo',
	telegram = 'telegram',
	telegram_icon = 'telegram_icon',
	timer = 'timer',
	top_x_header_logo = 'top_x_header_logo',
	top_x_header_logo_small = 'top_x_header_logo_small',
	video_instruction = 'video_instruction',
	vk = 'vk',
	lock_fill_small = 'lock_fill_small',
	invoice_2x_descr_icon = 'invoice_2x_descr_icon',
	lock_outline = 'lock_outline',
	verify_menu_icon = 'verify_menu_icon',
	play_icon = 'play_icon',
	list_outlined = 'list_outlined',
	green_light = 'green_light',
	decline_bonus = 'decline_bonus',
	new_bonus_flag = 'new_bonus_flag',
	new_freespins_flag = 'new_freespins_flag',
	green_gift_deposite = 'green_gift_deposite',
	menu_burger_icon = 'menu_burger_icon',
	person_sign_in = 'person_sign_in',
	notifications = 'notifications',
	notifications_granted = 'notifications_granted',
	close_android_banner = 'close_android_banner',
	android_app_icon = 'android_app_icon',
	star_icon = 'star_icon',
	bgaming_icon = 'bgaming_icon',
	icon_modal_1 = 'icon_modal_1',
	icon_modal_2 = 'icon_modal_2',
	icon_modal_3 = 'icon_modal_3',
	icon_modal_4 = 'icon_modal_4'
}

export enum DeviceType {
	mobile = 'mobile',
	desktop = 'desktop'
}
