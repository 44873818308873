import React from 'react';
import toast, { Toast } from 'react-hot-toast';
import { NotificattionContainer } from '../NotificattionContainer';
import { useLocales } from '@lib/hooks';
import { AnalyticsEventType } from '@types';
import { sendAnalyticsHandler, getImageByURL } from '@utils';
import s from './style.module.scss';
import { commonUiConfig } from '@config/common-ui';

type PropsType = {
	successInvoiceCount: number;
	t: Toast;
	navigate: () => void;
};

export const LowBalanceToast = (props: PropsType) => {
	const { t, successInvoiceCount, navigate } = props;

	const { localizeText } = useLocales({
		path: 'global',
		node: 'notifications'
	});

	const paymentHandler = () => {
		sendAnalyticsHandler(AnalyticsEventType?.popup_click_to_action, {
			name_popup: 'small_balance'
		});

		toast?.dismiss(t?.id);
		navigate && navigate();
	};

	return (
		<NotificattionContainer
			duration={commonUiConfig.infinityDuration}
			onClose={() =>
				sendAnalyticsHandler(AnalyticsEventType?.popup_close, {
					name_popup: 'small_balance'
				})
			}
			t={t}
		>
			<div className={s.container}>
				<img
					className={s.img}
					src={getImageByURL('../../../../../../assets/img/common/low_balance.webp')}
				/>

				<div className={s.textBlock}>
					<h3 className={s.textTitle}>{localizeText('low_balance')}</h3>
					<span className={s.textDescr}>
						{localizeText(
							successInvoiceCount < 5 ? 'low_balance_descr_bonuces' : 'low_balance_descr'
						)}
					</span>

					<button
						className={s.registerBtn}
						onClick={paymentHandler}
					>
						{localizeText('top_up_balance')}
					</button>
				</div>
			</div>
		</NotificattionContainer>
	);
};
